<template>
  <div>
    <p class="text-main text-sm font-bold py-3 pl-4">
      商品別売上ランキング
    </p>
    <ui-tab
      v-model="activeTabIndex"
      :items="['週間', '四半期', '総合']"
      class="mb-1"
    />
    <router-link
      v-for="(data, index) in productRanking[rankingPeriod]"
      :key="index"
      :to="`/sales/${data.id}`"
      class="flex justify-between items-center py-2 px-4 odd:bg-level-2"
    >
      <div class="text-main text-sm">
        {{ data.title }}
      </div>
      <div class="w-14 text-indigo-200 text-2xl text-right font-bold flex-shrink-0">
        {{ data.count }}
        <p class="text-xxs font-normal text-indigo-400">
          <span>詳細</span>
          <font-awesome-icon
            class="ml-1"
            :icon="['fas', 'chevron-right']"
          />
        </p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'SalesRanking',
  props: {
    productRanking: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rankingPeriod() {
      return ['weekly', 'quarterly', 'whole'][this.activeTabIndex];
    },
    activeTabIndex: {
      get() { return this.$store.getters['app/salesRankingActiveTabIndex']; },
      set(value) { this.$store.commit('app/salesRankingActiveTabIndex', value); },
    },
  },
};
</script>
