<template>
  <div>
    <p class="text-main text-sm font-bold py-3 pl-4">
      商品分析
    </p>
    <div class="py-12 text-center text-sub text-opacity-50">
      Comming soon
    </div>
    <div
      v-for="(item, index) in productMeta"
      :key="`productMeta${index}`"
      class="flex justify-between items-center py-2 px-4 odd:bg-level-2"
    >
      <div class="text-main text-xs flex-shrink-0">
        {{ item.title }}
      </div>
      <div class="w-14 text-xl text-right font-bold flex-grow text-indigo-200">
        {{ item.value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SalesRanking',
  props: {
    productCount: {
      type: Object,
      required: true,
    },
    salesAmount: {
      type: Object,
      required: true,
    },
  },
  computed: {
    productMeta() {
      return [
        {
          title: '商品総数',
          value: this.productCount.total,
        },
        {
          title: '商品別売上個数平均',
          value: this.productCount.sales_ave.toFixed(2),
        },
        {
          title: '商品別売上個数中央値',
          value: this.productCount.sales_mean,
        },
      ];
    },
  },
};
</script>
