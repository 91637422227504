export default {
  barChartOptions: () => ({
    theme: {
      mode: 'dark',
    },
    fill: {
      colors: ['#8da2fb'],
    },
    grid: {
      padding: {
        right: 5,
      },
      borderColor: 'rgba(255,255,255,.15)',
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      // enabled: false,
      offsetY: -20,
      style: {
        fontSize: '10px',
        fontWeight: 'bold',
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
        },
      },
    },
    stroke: {
      show: false,
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        format: 'M/d',
        datetimeUTC: false,
        style: {
          colors: 'rgba(255,255,255,.6)',
        },
      },
      axisTicks: {
        color: 'rgba(255,255,255,.15)',
      },
    },
    yaxis: {
      y: 0,
      offsetX: 0,
      offsetY: 0,
      labels: {
        style: {
          colors: 'rgba(255,255,255,.6)',
        },
        formatter: (value) => {
          const ranges = [
            { divider: 1e6, suffix: 'M' },
            { divider: 1e3, suffix: 'k' },
          ];
          for (let i = 0; i < ranges.length; i += 1) {
            if (value >= ranges[i].divider) {
              return `${(value / ranges[i].divider).toString()}${ranges[i].suffix}`;
            }
          }
          return value;
        },
      },
    },
    tooltip: {
      x: {
        format: 'yyyy/MM/dd',
      },
    },
  }),

  lineChartOptions: () => ({
    theme: {
      mode: 'dark',
    },
    colors: ['#8da2fb'],
    grid: {
      padding: {
        right: 5,
      },
      borderColor: 'rgba(255,255,255,.15)',
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      // enabled: false,
      offsetY: -20,
    },
    stroke: {
      curve: 'straight',
      width: 2,
    },
    markers: {
      size: 2,
      strokeColors: '#8da2fb',
      strokeWidth: 1,
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        format: 'M/d',
        datetimeUTC: false,
        style: {
          colors: 'rgba(255,255,255,.6)',
        },
      },
      axisTicks: {
        color: 'rgba(255,255,255,.15)',
      },
    },
    yaxis: {
      offsetX: 0,
      offsetY: 0,
      labels: {
        style: {
          colors: 'rgba(255,255,255,.6)',
        },
        formatter: (val) => val.toFixed(0),
      },
    },
    tooltip: {
      x: {
        format: 'yyyy/MM/dd',
      },
    },
  }),
};
