import axios from 'axios';

export default {
  async get(shopId) {
    const resp = await axios.get(`/api/sales?shopId=${shopId}`);
    return resp.data;
  },

  async getCustomer(shopId) {
    const resp = await axios.get(`/api/sales/customers?shopId=${shopId}`);
    return resp.data;
  },

  async searchProduct(val, shopId) {
    const resp = await axios.get(`/api/sales/products/search?q=${val}&shopId=${shopId}`);
    return resp.data;
  },

  async getSalesOnDate(date, shopId) {
    const resp = await axios.get(`/api/sales/orders/${date}?shopId=${shopId}`);
    return resp.data;
  },

  async getProduct(productId) {
    const resp = await axios.get(`/api/sales/products/${productId}`);
    return resp.data;
  },

  async getRankHistory(productId) {
    const resp = await axios.get(`/api/sales/rank-history/${productId}`);
    return resp.data;
  },

  async getCustomersMightLike(productId) {
    const resp = await axios.get(`/api/sales/customers-might-like/${productId}`);
    return resp.data;
  },

  async getProductMeta(productId) {
    const resp = await axios.get(`/api/sales/products/meta/${productId}`);
    return resp.data;
  },
};
