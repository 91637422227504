<template>
  <div>
    <ui-transition group>
      <div
        v-if="!query"
        :key="0"
      />
      <div
        v-else-if="searching"
        :key="1"
        class="text-center text-sub"
      >
        検索中です...
      </div>
      <div
        v-else-if="searchResults.length === 0"
        :key="2"
        class="text-center text-sub"
      >
        検索結果がありません
      </div>
      <div
        v-else
        :key="3"
      >
        <router-link
          v-for="item in searchResults"
          :key="item.id"
          class="flex items-center bg-level-3 rounded-lg my-1 text-sub px-4 py-2 text-sm"
          :to="`/sales/${item.id}`"
          replace
        >
          <div class="mr-2 flex-grow">
            {{ item.title }}
          </div>
          <div class="flex-shrink-0 text-sm">
            <font-awesome-icon
              :icon="['fas', 'chevron-right']"
            />
          </div>
        </router-link>
      </div>
    </ui-transition>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import SalesService from '../../services/SalesService';

export default {
  name: 'SalesSearchResult',
  props: {
    query: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searching: false,
      searchResults: [],
    };
  },
  computed: {
    shopId: {
      get() { return this.$store.getters['app/salesShopId']; },
      set(value) { this.$store.commit('app/salesShopId', value); },
    },
  },
  watch: {
    query(val) {
      if (!val) {
        this.searchResults = [];
        return;
      }
      this.searching = true;
      this.debouncedSearch(val);
    },
  },
  created() {
    this.debouncedSearch = debounce(this.search, 500);
  },
  methods: {
    async search(val) {
      this.searchResults = await SalesService.searchProduct(val, this.shopId);
      this.searching = false;
    },
  },
};
</script>

<style lang="sass">
.apexcharts-svg
  background: transparent!important
</style>
