<template>
  <div>
    <ui-transition group>
      <div
        v-if="isLoading"
        key="1"
      >
        <p class="text-center text-sub text-opacity-75 py-6 text-sm">
          読み込み中です
        </p>
      </div>
      <div
        v-else-if="notFound"
        key="2"
      >
        <p class="text-center text-sub text-opacity-75 py-6 text-sm">
          売上未発生か、商品が見つかりません
        </p>
      </div>
      <div
        v-else
        key="3"
      >
        <div
          class="bg-cover bg-no-repeat bg-center shadow-xl px-4 py-3 rounded-xl"
          :style="{
            backgroundImage: `linear-gradient(to bottom, rgba(19,23,32,.85), rgba(19,23,32,.85)), url(${thumbnail})`,
          }"
        >
          <p class="text-xxs inline-block px-3 py-1 mb-2 rounded-xl bg-green-800 text-green-200">
            <span>{{ salesDateFormatted }} 発売</span>
            <span
              v-if="isNew"
              class="ml-2 font-bold text-yellow-200"
            >New!</span>
          </p>
          <p class="text-sub text-xxs tracking-widest">
            {{ shopName }}
          </p>
          <h2 class="text-main font-bold">
            {{ title }}
          </h2>
          <div class="mt-2">
            <div class="flex items-center justify-between px-2 py-0.5">
              <div class="text-sub text-xs">
                売上順位
              </div>
              <div class="text-right">
                <div class="text-main font-extrabold inline-flex items-center">
                  <icon-rosette
                    v-if="currentRank <= 10"
                    class="inline-block w-6 h-6 mr-1"
                  />
                  <p>
                    <span class="text-2xl">{{ currentRank }}</span>位
                  </p>
                </div>
                <p class="text-sub text-xs">
                  <template v-if="lastMonthRank === undefined">
                    前月比：---
                  </template>
                  <template v-else-if="currentRank < lastMonthRank">
                    前月比：{{ lastMonthRank }}位 → {{ currentRank }}位
                    <font-awesome-icon
                      class="text-red-500"
                      :icon="['fas', 'arrow-alt-circle-up']"
                    />
                  </template>
                  <template v-else-if="currentRank > lastMonthRank">
                    前月比：{{ lastMonthRank }}位 → {{ currentRank }}位
                    <font-awesome-icon
                      class="text-blue-500"
                      :icon="['fas', 'arrow-alt-circle-down']"
                    />
                  </template>
                  <template v-else>
                    前月比：{{ lastMonthRank }}位 → {{ currentRank }}位
                    <font-awesome-icon
                      class="text-blue-gray-500"
                      :icon="['fas', 'arrow-alt-circle-right']"
                    />
                  </template>
                </p>
              </div>
            </div>
            <div class="flex items-center justify-between px-2 py-0.5">
              <div class="text-sub text-xs">
                総販売本数
              </div>
              <div class="text-right">
                <p class="text-main font-extrabold">
                  <span class="text-2xl">{{ totalSales }}</span>本
                </p>
                <p class="text-sub text-xs">
                  うち今月売上本数：{{ currentMonthSales }}本
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="mt-4 bg-level-1 shadow-xl pt-4 pl-0 pr-4 rounded-xl"
          :class="{'pb-4': orderCountGroupedByMonth.length <= 1}"
        >
          <p class="text-sm text-sub font-bold pl-4">
            売上本数遷移
          </p>
          <apexchart
            v-if="orderCountGroupedByMonth.length > 1"
            :height="160"
            type="bar"
            :series="orderHistChartSeries"
            :options="orderHistChartOptions"
          />
          <div
            v-else
            class="pb-4 ml-4 mt-4 text-center text-sub text-opacity-75 text-xs tracking-widest"
          >
            <p>まだデータが反映されていません</p>
          </div>
        </div>
        <div
          class="mt-4 bg-level-1 shadow-xl pt-4 pl-0 pr-4 rounded-xl"
          :class="{'pb-4': rankHistory.history.length <= 1}"
        >
          <p class="text-sm text-sub font-bold pl-4">
            順位遷移
          </p>
          <apexchart
            v-if="rankHistory.history.length > 1"
            :height="160"
            type="line"
            :series="rankHistChartSeries"
            :options="rankHistChartOptions"
          />
          <div
            v-else
            class="pb-4 ml-4 mt-4 text-center text-sub text-opacity-75 text-xs tracking-widest"
          >
            <p>まだデータが反映されていません</p>
          </div>
        </div>

        <div class="mt-4 bg-level-1 shadow-xl rounded-xl pt-4 pb-2">
          <p class="text-sm text-sub font-bold px-4 pb-2">
            購入者が他に購入した商品
          </p>
          <div v-if="customersMightLike.length > 0">
            <router-link
              v-for="product in customersMightLike"
              :key="product.id"
              :to="`/sales/${product.id}`"
              class="flex py-2 px-4 odd:bg-level-2 items-center"
              replace
            >
              <div class="text-cool-gray-300 text-sm mr-3 flex-grow">
                {{ product.title }}
              </div>
              <div class="flex-shrink-0 text-sm text-cool-gray-300">
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                />
              </div>
            </router-link>
          </div>
          <div
            v-else
            class="pb-4 mt-4 text-center text-sub text-opacity-75 text-xs tracking-widest"
          >
            <p>まだデータが反映されていません</p>
          </div>
        </div>
        <div class="mt-4 bg-level-1 shadow-xl p-4 rounded-xl">
          <p class="text-sm text-sub font-bold">
            キーワード
          </p>
          <p class="p-2 text-cool-gray-300 text-sm">
            {{ keywords }}
          </p>
        </div>
      </div>
    </ui-transition>
  </div>
</template>

<script>
import {
  parse, format, isThisMonth, addMonths, differenceInDays, eachMonthOfInterval,
} from 'date-fns';
import VueApexCharts from 'vue-apexcharts';
import chart from '../../code-define/chart';
import SalesService from '../../services/SalesService';

export default {
  name: 'SalesProductInner',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      productMetaIsLoading: true,
      isLoading: true,
      shopId: -1,
      shopName: '',
      title: '',
      keywords: 'ーーー',
      thumbnail: '',
      salesDate: null,
      orders: [],
      orderCountGroupedByMonth: [],
      customersMightLike: [],
      rankHistory: null,
      notFound: false,
    };
  },
  computed: {
    isNew() {
      return differenceInDays(new Date(), this.salesDate) < 30;
    },
    salesDateFormatted() {
      return format(this.salesDate, 'yyyy年M月d日');
    },
    totalSales() {
      return this.orders.reduce((p, c) => p + c.count, 0);
    },
    currentRank() {
      return this.rankHistory.history[this.rankHistory.history.length - 1];
    },
    lastMonthRank() {
      return this.rankHistory.history[this.rankHistory.history.length - 2];
    },
    currentMonthSales() {
      const targetOrders = this.orders.filter((order) => isThisMonth(parse(order.order_date, 'yyyy-MM-dd', new Date())));
      return targetOrders.reduce((p, c) => p + c.count, 0);
    },
    orderHistChartSeries() {
      return [{
        name: '売上本数',
        data: this.orderCountGroupedByMonth.map((order) => order.count),
      }];
    },
    orderHistChartOptions() {
      const options = chart.barChartOptions();
      options.xaxis.categories = this.orderCountGroupedByMonth.map((order) => order.date.toISOString());
      options.xaxis.labels.format = 'MMM \'yy';
      options.tooltip.x.format = 'MMM \'yy';
      options.dataLabels.enabled = false;
      delete options.plotOptions;
      return options;
    },
    rankHistChartSeries() {
      return [{
        name: '売上順位',
        data: this.rankHistory.history,
      }];
    },
    rankHistChartOptions() {
      const options = chart.lineChartOptions();
      let date = parse(`${this.rankHistory.first_sales_month}-01`, 'yyyy-MM-dd', new Date());
      options.xaxis.categories = this.rankHistory.history.reduce((p) => {
        p.push(date.toISOString());
        date = addMonths(date, 1);
        return p;
      }, []);
      options.xaxis.labels.format = 'MMM \'yy';
      options.tooltip.x.format = 'MMM \'yy';
      options.yaxis.reversed = true;
      options.yaxis.min = 1;
      options.yaxis.max = Math.max(...this.rankHistory.history);
      options.yaxis.tickAmount = Math.min(options.yaxis.max - options.yaxis.min, 5);
      options.yaxis.decimalsInFloat = 0;
      return options;
    },
  },
  watch: {
    id() {
      this.fetch();
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.productMetaIsLoading = true;
      this.isLoading = true;
      SalesService.getProductMeta(this.id).then((productMeta) => {
        this.productMetaIsLoading = false;
        if (!productMeta) {
          return;
        }
        this.thumbnail = productMeta.image;
        this.keywords = productMeta.keywords.join('・');
      });
      Promise.all([
        SalesService.getProduct(this.id),
        SalesService.getRankHistory(this.id),
        SalesService.getCustomersMightLike(this.id),
      ]).then(([product, rankHistory, customersMightLike]) => {
        if (!product) {
          this.notFound = true;
          return;
        }
        this.shopId = product.shop_id;
        this.shopName = product.shop_name;
        this.title = product.title;
        this.salesDate = parse(product.sales_date, 'yyyy/MM/dd', new Date());
        this.orders = product.orders;
        this.orderCountGroupedByMonth = eachMonthOfInterval({ start: this.salesDate, end: new Date() }).map((date) => {
          const targetOrders = product.orders.filter((order) => {
            const orderDate = parse(order.order_date, 'yyyy-MM-dd', new Date());
            return date <= orderDate && orderDate < addMonths(date, 1);
          });
          const count = targetOrders.reduce((p, c) => p + c.count, 0);
          return { date, count };
        });
        this.rankHistory = rankHistory;
        this.customersMightLike = customersMightLike;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="sass">
.apexcharts-svg
  background: transparent!important
</style>
