<template>
  <div>
    <p class="text-main text-sm font-bold pt-3 pl-4">
      MAU推移
    </p>
    <div class="pl-0 pr-4">
      <apexchart
        :height="200"
        type="bar"
        :series="customerChartSeries"
        :options="customerChartOptions"
      />
    </div>
    <div>
      <div
        v-for="(item, index) in customerData"
        :key="`totalMeta${index}`"
        class="flex justify-between items-center py-2 px-4 odd:bg-level-2"
      >
        <div class="text-main text-xs flex-shrink-0">
          {{ item.title }}
          <p
            v-if="item.supplement"
            class="text-xxs font-normal text-indigo-400"
          >
            <span>{{ item.supplement }}</span>
          </p>
        </div>
        <div
          class="w-14 text-xl text-right font-bold flex-grow"
          :class="item.color ? item.color : 'text-indigo-200'"
        >
          {{ item.value }}
          <p
            v-if="item.previousValue !== undefined"
            class="text-indigo-300 text-xxs font-normal"
          >
            <template v-if="item.value < item.previousValue">
              前々日比：{{ item.previousValue }} → {{ item.value }}
              <font-awesome-icon
                class="text-blue-500"
                :icon="['fas', 'arrow-alt-circle-down']"
              />
            </template>
            <template v-else-if="item.value > item.previousValue">
              前々日比：{{ item.previousValue }} → {{ item.value }}

              <font-awesome-icon
                class="text-red-500"
                :icon="['fas', 'arrow-alt-circle-up']"
              />
            </template>
            <template v-else>
              前々日比：{{ item.previousValue }} → {{ item.value }}
              <font-awesome-icon
                class="text-blue-gray-500"
                :icon="['fas', 'arrow-alt-circle-right']"
              />
            </template>
          </p>
        </div>
      </div>
    </div>
    <p class="text-sm text-main font-bold pt-12 pl-4">
      RFM分析
    </p>
    <div class="mt-4 pl-0 pr-0 relative">
      <p class="text-xxs font-bold text-sub absolute left-5 top-2">
        購入後経過日数
      </p>
      <apexchart
        :height="350"
        type="heatmap"
        :series="customerRfmChartSeries"
        :options="customerRfmChartOptions"
      />
      <p class="text-xxs font-bold text-sub absolute bottom-5 left-1/2">
        累計購入回数
      </p>
    </div>
    <div class="pl-8 pr-4 py-2 mx-4 text-indigo-200 text-xxs bg-level-2 rounded-lg">
      <ul class="list-disc">
        <li>セル内の数字は人数を表す</li>
        <li>セル色の濃度は購入金額ボリュームを表し、明るいほど大きい</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { parse } from 'date-fns';
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'SalesCustomer',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    analytics: {
      type: Object,
      required: true,
    },
    customerRanking: {
      type: Object,
      required: true,
    },
    customerMeta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rfmCounter: [[0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0], [0, 0, 0, 0, 0]],
    };
  },
  computed: {
    customerSegments() {
      return {
        champions: this.rfmCounter[4][0] + this.rfmCounter[4][1],
        loyalCustomers: this.rfmCounter[2][0] + this.rfmCounter[2][1] + this.rfmCounter[3][0] + this.rfmCounter[3][1],
        potentialLoyalists: this.rfmCounter[3][2] + this.rfmCounter[3][3] + this.rfmCounter[4][2] + this.rfmCounter[4][3],
        newCustomers: this.rfmCounter[4][4],
        promising: this.rfmCounter[3][4],
        needAttention: this.rfmCounter[2][2],
        aboutToSleep: this.rfmCounter[2][3] + this.rfmCounter[2][4],
        cannotLoseThem: this.rfmCounter[0][0] + this.rfmCounter[1][0],
        atRisk: this.rfmCounter[0][1] + this.rfmCounter[0][2] + this.rfmCounter[1][1] + this.rfmCounter[1][2],
        hibernating: this.rfmCounter[0][3] + this.rfmCounter[0][4] + this.rfmCounter[1][3] + this.rfmCounter[1][4],
      };
    },
    customerData() {
      const yen = new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' });
      const repeatRate = ((this.analytics.active[this.analytics.total.length - 2].c - this.analytics.new[this.analytics.total.length - 2].c) / this.analytics.total[this.analytics.total.length - 2].c) * 100;
      return [
        {
          title: '累計顧客数',
          value: this.analytics.total[this.analytics.total.length - 1].c,
        },
        {
          title: '前月のアクティブ顧客総数',
          value: this.analytics.active[this.analytics.total.length - 2].c,
          previousValue: this.analytics.active[this.analytics.total.length - 3].c,
        },
        {
          title: '前月の新規顧客数',
          value: this.analytics.new[this.analytics.total.length - 2].c,
          previousValue: this.analytics.new[this.analytics.total.length - 3].c,
        },
        {
          title: '前月の月間リピート率',
          value: `${repeatRate.toFixed(2)}%`,
        },
        {
          title: '顧客単価平均値',
          value: yen.format(this.customerMeta.sales_ave),
        },
        {
          title: '顧客単価中央値',
          value: yen.format(this.customerMeta.sales_mean),
        },
        {
          title: '顧客別累計購入額（年間最高）',
          value: yen.format(this.customerRanking.yearly[0].amount),
        },
        {
          title: '顧客別累計購入額（総合最高）',
          value: yen.format(this.customerRanking.whole[0].amount),
        },
      ];
    },
    customerRfmChartSeries() {
      const series = [
        { name: '366日以上', data: [{ x: '20回以上', y: 0 }, { x: '10〜20回', y: 0 }, { x: '5〜9回', y: 0 }, { x: '2〜4回', y: 0 }, { x: '1回', y: 0 }] },
        { name: '365日以内', data: [{ x: '20回以上', y: 0 }, { x: '10〜20回', y: 0 }, { x: '5〜9回', y: 0 }, { x: '2〜4回', y: 0 }, { x: '1回', y: 0 }] },
        { name: '180日以内', data: [{ x: '20回以上', y: 0 }, { x: '10〜20回', y: 0 }, { x: '5〜9回', y: 0 }, { x: '2〜4回', y: 0 }, { x: '1回', y: 0 }] },
        { name: '90日以内', data: [{ x: '20回以上', y: 0 }, { x: '10〜20回', y: 0 }, { x: '5〜9回', y: 0 }, { x: '2〜4回', y: 0 }, { x: '1回', y: 0 }] },
        { name: '30日以内', data: [{ x: '20回以上', y: 0 }, { x: '10〜20回', y: 0 }, { x: '5〜9回', y: 0 }, { x: '2〜4回', y: 0 }, { x: '1回', y: 0 }] },
      ];
      const determinFreqRank = (freq) => {
        if (freq >= 20) {
          return '20回以上';
        }
        if (freq >= 10) {
          return '10〜20回';
        }
        if (freq >= 5) {
          return '5〜9回';
        }
        if (freq >= 2) {
          return '2〜4回';
        }
        return '1回';
      };
      this.analytics.rfm.forEach((item) => {
        const fRank = determinFreqRank(item.f);
        if (item.r <= 30) {
          this.rfmCounter[4][series[4].data.findIndex((v) => v.x === fRank)] += 1;
          series[4].data.find((v) => v.x === fRank).y += item.m;
        } else if (item.r <= 90) {
          this.rfmCounter[3][series[3].data.findIndex((v) => v.x === fRank)] += 1;
          series[3].data.find((v) => v.x === fRank).y += item.m;
        } else if (item.r <= 180) {
          this.rfmCounter[2][series[2].data.findIndex((v) => v.x === fRank)] += 1;
          series[2].data.find((v) => v.x === fRank).y += item.m;
        } else if (item.r <= 365) {
          this.rfmCounter[1][series[1].data.findIndex((v) => v.x === fRank)] += 1;
          series[1].data.find((v) => v.x === fRank).y += item.m;
        } else {
          this.rfmCounter[0][series[0].data.findIndex((v) => v.x === fRank)] += 1;
          series[0].data.find((v) => v.x === fRank).y += item.m;
        }
      });
      return series;
    },

    customerRfmChartOptions() {
      const options = {
        theme: {
          mode: 'dark',
        },
        colors: [
          '#B794F4',
        ],
        chart: {
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        stroke: {
          colors: ['#141821'],
          width: 4,
        },
        dataLabels: {
          enabled: true,
          formatter: (val, { seriesIndex, dataPointIndex }) => this.getRfmPeopleCount(seriesIndex, dataPointIndex),
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
          },
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: 'rgba(255,255,255,.6)',
            },
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: 'rgba(255,255,255,.6)',
            },
          },
        },
      };
      return options;
    },

    customerChartSeries() {
      return [{
        name: '新規購入顧客数',
        type: 'column',
        data: this.analytics.new.slice(0, -1).map((item) => item.c),
      }, {
        name: '既存購入顧客数',
        type: 'column',
        data: this.analytics.active.slice(0, -1).map((item, i) => item.c - this.analytics.new[i].c),
      }];
    },
    customerChartOptions() {
      const options = {
        theme: {
          mode: 'dark',
        },
        grid: {
          padding: {
            right: 5,
          },
          borderColor: 'rgba(255,255,255,.15)',
        },
        chart: {
          toolbar: {
            show: false,
          },
          stacked: true,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
          },
        },
        stroke: {
          show: false,
        },
        xaxis: {
          type: 'datetime',
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          labels: {
            format: 'MMM \'yy',
            datetimeUTC: false,
            style: {
              colors: 'rgba(255,255,255,.6)',
            },
          },
          axisTicks: {
            color: 'rgba(255,255,255,.15)',
          },
          categories: this.analytics.total.slice(0, -1).map((item) => parse(`${item.m}-01`, 'yyyy-MM-dd', new Date()).toISOString()),
        },
        yaxis: {
          y: 0,
          offsetX: 0,
          offsetY: 0,
          labels: {
            style: {
              colors: 'rgba(255,255,255,.6)',
            },
          },
        },
        tooltip: {
          x: {
            format: 'MMM \'yy',
          },
        },
      };
      return options;
    },
  },
  methods: {
    getRfmPeopleCount(x, y) {
      return this.rfmCounter[x][y];
    },
  },
};
</script>
