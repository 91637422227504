<template>
  <div>
    <p class="text-main text-sm font-bold py-3 pl-4">
      売上推移
    </p>
    <ui-tab
      v-model="activeTabIndex"
      :items="['日次', '月次', '総合']"
      class="mb-4"
    />
    <div
      v-if="activeTabIndex === 0"
      key="sales-history-chart-0"
    >
      <p class="text-xxs text-sub pl-4">
        前日の売上本数
      </p>
      <p class="text-4xl font-black text-main pl-4">
        {{ salesCount.daily[salesCount.daily.length - 1].value }}
      </p>
      <p class="text-sub text-xxs pl-4 mb-2">
        <template v-if="salesCount.daily[salesCount.daily.length - 1].value < salesCount.daily[salesCount.daily.length - 2].value">
          前々日比：-{{ salesCount.daily[salesCount.daily.length - 2].value - salesCount.daily[salesCount.daily.length - 1].value }}
          <font-awesome-icon
            class="text-blue-500"
            :icon="['fas', 'arrow-alt-circle-down']"
          />
        </template>
        <template v-else-if="salesCount.daily[salesCount.daily.length - 1].value > salesCount.daily[salesCount.daily.length - 2].value">
          前々日比：+{{ salesCount.daily[salesCount.daily.length - 1].value - salesCount.daily[salesCount.daily.length - 2].value }}

          <font-awesome-icon
            class="text-red-500"
            :icon="['fas', 'arrow-alt-circle-up']"
          />
        </template>
        <template v-else>
          前々日比：±0
          <font-awesome-icon
            class="text-blue-gray-500"
            :icon="['fas', 'arrow-alt-circle-right']"
          />
        </template>
      </p>
      <div class="pl-0 pr-4">
        <apexchart
          :height="160"
          type="bar"
          :series="dailySalesCountChartSeries"
          :options="dailySalesCountChartOptions"
        />
      </div>
      <div class="px-4 pb-4">
        <ui-transition>
          <div v-if="!isLoading && selectedDate">
            <div class="text-right">
              <p
                v-if="sales.length > 0"
                class="text-sub text-sm mb-2"
                @click="clearFetchedSales"
              >
                <font-awesome-icon :icon="['fas', 'minus-circle']" />
                非表示にする
              </p>
            </div>
            <div
              v-for="item in sales"
              :key="item.id"
              class="flex items-center justify-between"
            >
              <router-link
                :to="`/sales/${item.id}`"
                class="flex-grow text-indigo-400 truncate text-xs py-0.5"
              >
                {{ item.title }}
              </router-link>
              <p class="w-6 text-right text-sub text-xs flex-shrink-0">
                {{ item.count }}
              </p>
            </div>
          </div>
        </ui-transition>
      </div>
    </div>
    <div
      v-else-if="activeTabIndex === 1"
      key="sales-history-chart-2"
    >
      <p class="text-xxs text-sub pl-4">
        前月の売上本数
      </p>
      <p class="text-4xl font-black text-main pl-4">
        {{ salesCount.monthly[salesCount.monthly.length - 2].value }}
      </p>
      <p class="text-sub text-xxs pl-4 mb-2">
        <template v-if="salesCount.monthly[salesCount.monthly.length - 2].value < salesCount.monthly[salesCount.monthly.length - 3].value">
          前々月比：{{ salesCount.monthly[salesCount.monthly.length - 3].value - salesCount.monthly[salesCount.monthly.length - 2].value }}
          <font-awesome-icon
            class="text-blue-500"
            :icon="['fas', 'arrow-alt-circle-down']"
          />
        </template>
        <template v-else-if="salesCount.monthly[salesCount.monthly.length - 2].value > salesCount.monthly[salesCount.monthly.length - 3].value">
          前々月比：+{{ salesCount.monthly[salesCount.monthly.length - 3].value - salesCount.monthly[salesCount.monthly.length - 2].value }}

          <font-awesome-icon
            class="text-red-500"
            :icon="['fas', 'arrow-alt-circle-up']"
          />
        </template>
        <template v-else>
          前々月比：0
          <font-awesome-icon
            class="text-blue-gray-500"
            :icon="['fas', 'arrow-alt-circle-right']"
          />
        </template>
      </p>
      <div class="pl-0 pr-4">
        <apexchart
          :height="160"
          type="bar"
          :series="monthlySalesCountChartSeries"
          :options="monthlySalesCountChartOptions"
        />
      </div>
    </div>
    <div
      v-else-if="activeTabIndex === 2"
      key="sales-history-chart-3"
      class="pb-4"
    >
      <div class="pl-0 pr-4">
        <apexchart
          :height="200"
          type="area"
          :series="totalSalesCountChartSeries"
          :options="totalSalesCountChartOptions"
        />
      </div>
      <div
        v-for="(item, index) in totalMeta"
        :key="`totalMeta${index}`"
        class="flex justify-between items-center py-2 px-4 odd:bg-level-2"
      >
        <div class="text-main text-xs flex-shrink-0">
          {{ item.title }}
          <p
            v-if="item.supplement"
            class="text-xxs font-normal text-indigo-400"
          >
            <span>{{ item.supplement }}</span>
          </p>
        </div>
        <div
          class="w-14 text-xl text-right font-bold flex-grow"
          :class="item.color ? item.color : 'text-indigo-200'"
        >
          {{ item.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format, parse } from 'date-fns';
import VueApexCharts from 'vue-apexcharts';
import chart from '../../code-define/chart';
import SalesService from '../../services/SalesService';

export default {
  name: 'SalesHistoryChart',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    salesCount: {
      type: Object,
      required: true,
    },
    salesAmount: {
      type: Object,
      required: true,
    },
    shopId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      activeTabIndex: 0,
      selectedDate: null,
      isLoading: false,
      sales: [],
    };
  },
  computed: {
    totalMeta() {
      const salesAmountThisYear = this.salesAmount.monthly.slice(-13, -1).reduce((p, c) => p + c.value, 0);
      const salesAmountLastYear = this.salesAmount.monthly.slice(-25, -13).reduce((p, c) => p + c.value, 0);
      const salesGrowth = ((salesAmountThisYear - salesAmountLastYear) / salesAmountLastYear) * 100;

      const yen = new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' });
      return [
        {
          title: '総売上本数',
          value: this.salesCount.total,
        },
        {
          title: '売上本数最高(1日)',
          value: this.salesCount.max.daily.value,
          supplement: `${format(parse(`${this.salesCount.max.daily.date}`, 'yyyy-MM-dd', new Date()), 'yyyy年M月d日')}`,
        },
        {
          title: '売上本数最高(1ヶ月)',
          value: this.salesCount.max.monthly.value,
          supplement: `${format(parse(`${this.salesCount.max.monthly.date}-01`, 'yyyy-MM-dd', new Date()), 'yyyy年M月')}`,
        },
        {
          title: '総売上金額',
          value: yen.format(this.salesAmount.total),
        },
        {
          title: '売上金額最高(1日)',
          value: yen.format(this.salesAmount.max.daily.value),
          supplement: `${format(parse(`${this.salesAmount.max.daily.date}`, 'yyyy-MM-dd', new Date()), 'yyyy年M月d日')}`,
        },
        {
          title: '売上金額最高(1ヶ月)',
          value: yen.format(this.salesAmount.max.monthly.value),
          supplement: `${format(parse(`${this.salesAmount.max.monthly.date}-01`, 'yyyy-MM-dd', new Date()), 'yyyy年M月')}`,
        },
        {
          title: '売上高成長率(単年)',
          value: `${salesGrowth.toFixed(2)}%`,
          color: salesGrowth < 0 ? 'text-red-200' : null,
        },
      ];
    },
    totalSalesCountChartSeries() {
      return [{
        name: '売上本数',
        data: this.salesCount.monthly.slice(0, -1).map((item) => item.value),
      }];
    },
    totalSalesCountChartOptions() {
      const options = chart.lineChartOptions();
      options.markers.size = 0;
      options.dataLabels.enabled = false;
      options.stroke.curve = 'smooth';
      options.xaxis.categories = this.salesCount.monthly.slice(0, -1).map((item) => parse(`${item.date}-01`, 'yyyy-MM-dd', new Date()).toISOString());
      options.xaxis.labels.format = 'MMM \'yy';
      options.tooltip.x.format = 'MMM \'yy';
      return options;
    },
    monthlySalesCountChartSeries() {
      return [{
        name: '売上本数',
        data: this.salesCount.monthly.slice(-12).map((item) => item.value),
      }];
    },
    monthlySalesCountChartOptions() {
      const options = chart.barChartOptions();
      options.xaxis.categories = this.salesCount.monthly.slice(-12).map((item) => parse(`${item.date}-01`, 'yyyy-MM-dd', new Date()).toISOString());
      options.xaxis.labels.format = 'MMM \'yy';
      options.tooltip.x.format = 'MMM \'yy';
      return options;
    },
    dailySalesCountChartSeries() {
      return [{
        name: '売上本数',
        data: this.salesCount.daily.map((item) => item.value),
      }];
    },
    dailySalesCountChartOptions() {
      const options = chart.barChartOptions();
      options.chart.events = {};
      options.chart.events.dataPointSelection = (evt, ctx, { dataPointIndex }) => {
        const { date } = this.salesCount.daily[dataPointIndex];
        this.fetchSalesOn(date);
      };
      options.xaxis.categories = this.salesCount.daily.map((item) => parse(item.date, 'yyyy-MM-dd', new Date()).toISOString());
      return options;
    },
  },
  watch: {
    activeTabIndex() {
      this.clearFetchedSales();
    },
  },
  methods: {
    async fetchSalesOn(date) {
      if (this.selectedDate === date) {
        this.clearFetchedSales();
        return;
      }
      this.isLoading = true;
      this.selectedDate = date;
      this.sales = await SalesService.getSalesOnDate(date, this.shopId);
      this.isLoading = false;
    },
    clearFetchedSales() {
      this.sales = [];
      this.selectedDate = null;
    },
  },
};
</script>

<style lang="sass">
.apexcharts-svg
  background: transparent!important
</style>
