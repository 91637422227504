<template>
  <div>
    <ui-input
      v-model="searchBox"
      class="mb-4"
      placeholder="検索"
    />
    <sales-search-result :query="searchBox" />
    <ui-transition>
      <keep-alive>
        <sales-product-inner
          v-if="!searchBox"
          :id="id"
        />
      </keep-alive>
    </ui-transition>
  </div>
</template>

<script>
import SalesSearchResult from '../components/sales/SalesSearchResult.vue';
import SalesProductInner from '../components/sales/SalesProductInner.vue';

export default {
  name: 'SalesProduct',
  components: {
    SalesSearchResult,
    SalesProductInner,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchBox: '',
    };
  },
  watch: {
    id() {
      this.searchBox = '';
    },
  },
};
</script>

<style lang="sass">
.apexcharts-svg
  background: transparent!important
</style>
