<template>
  <div>
    <ui-select
      v-model="shopId"
      class="mb-6"
      :options="filteredShops"
      label="レーベルを選択"
      @change="fetch"
    />
    <ui-transition>
      <article v-if="!isLoading" key="sales-main">
        <sales-history-chart
          :sales-count="analytics.sales_count"
          :sales-amount="analytics.sales_amount"
          :shop-id="shopId"
          class="bg-level-1 shadow-xl pt-2 pb-0 rounded-xl"
        />
        <sales-ranking
          :product-ranking="analytics.product_ranking"
          :product-count="analytics.product"
          :sales-amount="analytics.sales_amount"
          class="bg-level-1 shadow-xl rounded-xl mt-4 pt-2 pb-4"
        />
        <sales-customer
          :analytics="customerAnalytics"
          :customer-meta="analytics.customer"
          :customer-ranking="analytics.customer_ranking"
          class="bg-level-1 shadow-xl rounded-xl mt-4 pt-2 pb-4"
        />
        <sales-product-info
          :product-count="analytics.product"
          :sales-amount="analytics.sales_amount"
          class="bg-level-1 shadow-xl rounded-xl mt-4 pt-2 pb-4"
        />
      </article>
      <div v-else key="sales-loading">
        <p class="text-center text-sub text-opacity-75 py-6 text-sm">
          読み込み中です
        </p>
      </div>
    </ui-transition>
    <ui-dialog
      v-model="cautionDialog"
      title="注意"
      type="warning"
      description="業務遂行の目的外に使用したり、第三者に漏洩したり、弊社の事前書面の承諾なく開示することを禁じます"
    >
      <ui-button color="primary" class="mb-2" @click="onCloseCautionDialog()">
        同意する
      </ui-button>
      <ui-button @click="$router.back()">
        同意しない
      </ui-button>
    </ui-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SalesService from '../services/SalesService'
import SalesHistoryChart from '../components/sales/SalesHistoryChart.vue'
import SalesRanking from '../components/sales/SalesRanking.vue'
import SalesProductInfo from '../components/sales/SalesProductInfo.vue'
import SalesCustomer from '../components/sales/SalesCustomer.vue'

export default {
  name: 'Sales',
  components: {
    SalesHistoryChart,
    SalesRanking,
    SalesProductInfo,
    SalesCustomer,
  },
  data() {
    return {
      shops: [
        { value: 1, text: '足フェチ＆くすぐりフェチマスター' },
        { value: 2, text: '舌ベロマスター' },
        { value: 3, text: '口内・噛みつき・歯フェチマスター' },
      ],
      isLoading: true,
      analytics: null,
      customerAnalytics: null,
      cautionDialog: false,
    }
  },
  computed: {
    ...mapGetters('user', ['role']),
    shopId: {
      get() {
        return this.$store.getters['app/salesShopId']
      },
      set(value) {
        this.$store.commit('app/salesShopId', value)
      },
    },
    filteredShops() {
      if (this.role === 'admin') {
        return this.shops
      }
      if (this.role === 'creator_tic_plus') {
        return [this.shops[0]]
      }
      if (this.role === 'creator_ton_plus') {
        return [this.shops[1]]
      }
      if (this.role === 'creator_bite_plus') {
        return [this.shops[2]]
      }
      return []
    },
  },
  created() {
    if (
      this.filteredShops.length > 0 &&
      !this.filteredShops.find(shop => shop.value === this.shopId)
    ) {
      this.shopId = this.filteredShops[0].value
    }
  },
  mounted() {},
  methods: {
    async fetch() {
      this.isLoading = true
      ;[this.analytics, this.customerAnalytics] = await Promise.all([
        SalesService.get(this.shopId),
        SalesService.getCustomer(this.shopId),
      ])
      this.isLoading = false
    },
    onCloseCautionDialog() {
      this.cautionDialog = false
      setTimeout(() => {
        this.fetch()
      }, 300)
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name !== 'SalesProduct') {
        // eslint-disable-next-line no-param-reassign
        vm.cautionDialog = true
      } else {
        // eslint-disable-next-line no-param-reassign
        vm.fetch()
      }
    })
  },
}
</script>
